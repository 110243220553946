import { AnimatePresence } from "framer-motion";
import { useState } from "react";

import Header from "./components/Header";
import Navigation from "./components/Navigation";

import { Section } from "./components/layout";

import { colors } from "./helpers/variables";

import Logo from "./img/logo-tremplin-bleu.png";
import { ReactComponent as Behance } from "./img/logo-behance.svg";
import { ReactComponent as Instagram } from "./img/logo-instagram.svg";
import { ReactComponent as Linkedin } from "./img/logo-linkedin.svg";
import { ReactComponent as LogoMail } from "./img/logo-mail.svg";

import "./css/App.css";
import { Totems } from "./components/Totems";
import Carousel from "./components/Carousel";

function App() {
  const [toggleTrombi, setToggleTrombi] = useState(false);

  const behanceLink = "https://www.behance.net/tremplin-numerique";
  const instagramLink = "https://www.instagram.com/tremplin.numerique";
  const linkedinLink = "https://linkedin.com/company/tremplin-numerique";
  const email = "tremplin.numerique@gmail.com";

  return (
    <div className="app">
      <Navigation />
      <Header />
      <Section name="agence">
        <div className="content">
          <div className="logo">
            <img src={Logo} alt="logo Tremplin Numerique" />
          </div>
          <div className="text">
            <p style={{ color: colors.yellow }}>
              Agence inclusive de création de contenus
            </p>
            <p>
              Nous sommes inspirés par notre ancrage avec les banlieues et
              créatifs grâce à nos talents en insertion professionnelle, issus
              de tous les horizons
            </p>
            <p style={{ color: colors.yellow }}>Centre de formation</p>
            <p>
              Nous sommes engagés contre les inégalités et ouverts sur les
              enjeux d’avenir notamment l'empowerment numérique
            </p>
            <p>Nous sommes</p>
            <p style={{ color: colors.yellow }}>Tremplin Numérique</p>
          </div>
        </div>
      </Section>
      <Section name="portfolio">
        <div className="content">
          <div className="text">
            <p>Faire de la com’ comme personne</p>
          </div>
          <div className="logo">
            <a href={behanceLink} rel="noreferrer" target="_blank">
              <Behance />
            </a>
          </div>
          <div className="text">
            <a href={behanceLink}>Voir notre portfolio</a>
          </div>
        </div>
      </Section>
      <Section name="actu">
        <div className="content">
          <div className="text">
            <p style={{ color: colors.purple }}>
              Pour faire la différence, il faut faire différemment
            </p>
          </div>
          <div className="logo">
            <a href={instagramLink} rel="noreferrer" target="_blank">
              <Instagram />
            </a>
          </div>
          <div className="logo">
            <a href={linkedinLink} rel="noreferrer" target="_blank">
              <Linkedin />
            </a>
          </div>
        </div>
      </Section>
      <Section name="team">
        <div className="content">
          <div className="text">
            <p>vous n’êtes pas comme les autres, nous non plus</p>
          </div>
          <div className="trombi">
            <AnimatePresence>
              {!toggleTrombi ? <Totems /> : <Carousel />}
            </AnimatePresence>
          </div>
          <div className="text sm">
            60 salariés en insertion professionnelle formés au métier
            d'Opérateur/Opératrice Numérique Multimédia ONM
          </div>
          <button
            onClick={() => {
              setToggleTrombi(!toggleTrombi);
            }}
          >
            Découvrez toute l'équipe
          </button>
        </div>
      </Section>
      <Section name="contact">
        <div className="content">
          <div className="text">
            <p>
              Pour penser différemment, <br />
              pensez à nous
            </p>
          </div>
          <div className="logo">
            <a href={email}>
              <LogoMail />
            </a>
          </div>
        </div>
      </Section>
    </div>
  );
}

export default App;
