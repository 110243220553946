import React, { useEffect, useRef, useState } from "react";
import { colors } from "../helpers/variables";

import { ReactComponent as Burger } from "../img/menu.svg";
import { ReactComponent as Close } from "../img/close.svg";

export default function Navigation() {
  const navigationRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleResizes = () => {
    if (window.innerWidth > 1024) {
      setShowMenu(true);
      navigationRef.current.style.width = "20%";
    }
    if (window.innerWidth <= 1024 && showMenu) {
      setShowMenu(false);
      navigationRef.current.style.width = "100%";
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizes);
    return () => {
      window.removeEventListener("resize", handleResizes);
    };
  }, []);

  return (
    <div
      className="navigation"
      ref={navigationRef}
      style={{ width: showMenu ? "100%" : "20%" }}
    >
      <button
        className="toggle__button"
        onClick={() => {
          setShowMenu(!showMenu);
        }}
      >
        {showMenu ? <Close /> : <Burger />}
      </button>
      {showMenu ? <Nav /> : null}
      {window.innerWidth >= 1280 ? <Nav /> : null}
    </div>
  );
}

const Nav = () => {
  return (
    <ul className="nav">
      <li className="items" style={{ backgroundColor: colors.blue }}>
        <a href="#agence">Agence</a>
      </li>
      <li className="items" style={{ backgroundColor: colors.red }}>
        <a href="#portfolio">Portfolio</a>
      </li>
      <li className="items" style={{ backgroundColor: colors.green }}>
        <a href="#actu">Actualités</a>
      </li>
      <li
        className="items"
        style={{ backgroundColor: colors.grey, textTransform: "capitalize" }}
      >
        <a href="#team">équipe</a>
      </li>
      <li className="items" style={{ backgroundColor: colors.yellow }}>
        <a href="#contact">Contact</a>
      </li>
    </ul>
  );
};
