import { delay } from "../variables";

export const logoVar = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: "easeInOut",
      delay: delay + 0.8,
    },
  },
};

export const headerBackgroundVar = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: "easeInOut",
      delay: delay + 0.2,
      delayChildren: delay + 0.4,
      staggerChildren: 0.2,
    },
  },
};

export const stripVar = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};
