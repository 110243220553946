export const colors = {
  blue: "#3069D7",
  red: "#C82323",
  green: "#005B3D",
  grey: "#A8959C",
  yellow: "#F7D740",
  purple: "#C00068",
};

export const delay = 0.6;
