import React, { useState, useEffect } from "react";
import { useAxios } from "../helpers/axios";

import totem from "../img/totem1.svg";
import totem2 from "../img/totem2.svg";

export const Totems = () => {
  const portraitPaths = useAxios("/photos/portraits");
  const [portraitsToShow, setPortraitsToShow] = useState({
    first: "",
    seconde: "",
  });
  const staticPath = "http://api.maignanb.com/static/portraits/";

  useEffect(() => {
    if (portraitPaths !== null) {
      let first =
        portraitPaths[Math.floor(Math.random() * portraitPaths.length - 1)];
      let seconde =
        portraitPaths[Math.floor(Math.random() * portraitPaths.length - 1)];
      if (seconde === first) {
        seconde =
          portraitPaths[Math.floor(Math.random() * portraitPaths.length)];
      }
      setPortraitsToShow({
        first: first,
        seconde: seconde,
      });
    }
  }, [portraitPaths]);

  return (
    <React.Fragment>
      <div className="totem__holder">
        <div className="portrait__container">
          <img src={`${staticPath}${portraitsToShow.first}`} alt="portrait" />
          <div className="portrait__overlay"></div>
        </div>
        <img src={totem} alt="totem1" />
      </div>
      <div className="totem__holder">
        <div className="portrait__container">
          <img src={`${staticPath}${portraitsToShow.seconde}`} alt="portrait" />
          <div className="portrait__overlay"></div>
        </div>
        <img src={totem2} alt="totem2" />
      </div>
    </React.Fragment>
  );
};
