import { motion } from "framer-motion";
import { useState } from "react";
import { cardInfoVar } from "../helpers/variants/card.variants";

export const Card = ({ data }) => {
  const [isHovered, setIsHovered] = useState(false);
  const staticPath = "http://api.maignanb.com/static/images/";

  const toggleInfos = () => {
    setIsHovered(!isHovered);
  };

  return (
    <motion.div
      className="card"
      onPointerEnter={toggleInfos}
      onPointerLeave={toggleInfos}
      onTap={window.innerWidth <= 414 ? toggleInfos : null}
    >
      <div className="image__container">
        <img src={`${staticPath}${data.path}`} alt={data.nom} />
      </div>
      <motion.div
        className="infos"
        style={{ backgroundColor: data.color }}
        variants={cardInfoVar}
        initial="hidden"
        animate={isHovered ? "show" : "hidden"}
      >
        <h4 className="poste" style={{ fontSize: 14 }}>
          {data.groupe}
        </h4>
        <h3 className="infos__name">{data.nom}</h3>
        <div>
          <p className="infos__trait">{data.poste}</p>
          <p className="infos__trait" style={{ fontSize: "16px" }}>
            {data.desc}
          </p>
        </div>
      </motion.div>
    </motion.div>
  );
};
