import { useEffect, useState } from "react";
import axios from "axios";

export function useAxios(path) {
  const [data, setData] = useState(null);

  useEffect(() => {
    axios({
      method: "get",
      url: `https://api.maignanb.com/v1${path}`,
    }).then((res, err) => {
      if (err) throw err;
      setData(res.data);
    });
  }, [path]);

  return data;
}
