import React from "react";
import { motion } from "framer-motion";
import { colors } from "../helpers/variables";
import {
  headerBackgroundVar,
  stripVar,
  logoVar,
} from "../helpers/variants/header.variants";
import { Section } from "./layout";

import { ReactComponent as MainLogo } from "../img/logo-tremplin-header.svg";

export default function Header() {
  return (
    <Section name="header">
      <Strips />
      <div className="content">
        <motion.div
          className="logo"
          variants={logoVar}
          initial="hidden"
          animate="show"
        >
          <MainLogo />
        </motion.div>
        <motion.div
          className="text"
          style={{ color: colors.purple }}
          variants={logoVar}
          initial="hidden"
          animate="show"
        >
          <p>
            COM’ <br />
            UN AIR <br />
            DE <br />
            JAMAIS <br />
            VU
          </p>
        </motion.div>
      </div>
    </Section>
  );
}

const Strips = () => {
  return (
    <motion.div className="header__background">
      <motion.div className="strip" style={{ backgroundColor: colors.blue }} />
      <motion.div className="strip" style={{ backgroundColor: colors.red }} />
      <motion.div className="strip" style={{ backgroundColor: colors.green }} />
      <motion.div className="strip" style={{ backgroundColor: colors.grey }} />
      <motion.div
        className="strip"
        style={{ backgroundColor: colors.yellow }}
      />
    </motion.div>
  );
};
