export const employees = {
  staff: [
    {
      nom: "Marcela PEREZ",
      poste: "Architecte DPLG, la boss",
      desc: "Notre Cassandre toujours une longueur d’avance.",
      groupe: "STAFF",
    },
    {
      nom: "Dimma VEZZANI",
      poste: "Réalisatrice et comédienne",
      desc: "Cofondatrice, complice de toutes les folies. Elle maîtrise ” le jeu”.",
      groupe: "STAFF",
    },
    {
      nom: "Rebecca GADEGBEKU",
      poste: "Community manager, chef de notre Agence “Le Pont”",
      desc: "En action permanente, elle pilote tout de A à Z.",
      groupe: "STAFF",
    },
    {
      nom: "Helena PALLARES",
      poste: "Graphiste, plasticienne, passionnée de photo",
      desc: "Elle gère notre com et plus encore !",
      groupe: "STAFF",
    },
    {
      nom: "Maryline COURTOIS",
      poste: "Secrétaire de direction, responsable RH",
      desc: "Notre premier et dernier recours, elle dialogue avec “la Machine”.",
      groupe: "STAFF",
    },
    {
      nom: "Milano BONARIC-BORIC",
      poste: "Ingénieur informatique",
      desc: 'Notre “saint Bernard", Il gère notre matrix et le reste !',
      groupe: "STAFF",
    },
  ],
  web: [
    {
      nom: "Ben Ammi MAIGNAN",
      poste: "Webdesigner / Développeur",
      desc: "Responsable du Pôle webdesign, entre web et 3D, il explore tout !",
      groupe: "WEB",
    },
    {
      nom: "Louis MOREAU",
      poste: "Webdesigner / Développeur",
      desc: "Il code du web et encode notre machine pour la rendre encore plus dingue.",
      groupe: "WEB",
    },
  ],
  graphisme: [
    {
      nom: "Cédric NAGAU",
      poste: "Illustrateur, designer, chef de notre Agence “Le Loft”",
      desc: "Il maîtrise le stylet, “le style” et la bonne humeur",
      groupe: "GRAPHISME",
    },
    {
      nom: "Audrey LIEBY",
      poste: "Graphiste / Motion Designer",
      desc: "Responsable du Pôle Graphisme, elle structure la prod dans les temps !",
      groupe: "GRAPHISME",
    },
    {
      nom: "Paulo BOUCHENDHOMME",
      poste: "Graphiste Print",
      desc: "Méthodique, il gère nos travaux d’édition.",
      groupe: "GRAPHISME",
    },
    {
      nom: "Laura CRICHI",
      poste: "ONM / Graphisme",
      desc: "Dessinatrice endurante, elle se donne les moyens de réussir !",
      groupe: "GRAPHISME",
    },
    {
      nom: "Alaa ASHRAF KAMAL ELDIN",
      poste: "ONM / Graphisme",
      desc: "Dynamique, à fond sur les RS !",
      groupe: "GRAPHISME",
    },
    {
      nom: "Lila BENARBIA",
      poste: "ONM / Graphisme",
      desc: "Spontanée, généreuse, c’est notre Super coach RH ",
      groupe: "GRAPHISME",
    },
  ],
  petiteAgence: [
    {
      nom: "Oksana WOTIN",
      poste: "ONM / Graphisme et art",
      desc: "Artiste, elle organise des prods  avec tact et justesse",
      groupe: "GRAPHISME",
    },
    {
      nom: "Sylvie COLAS",
      poste: "ONM / Graphisme et Arts",
      desc: "Sensible avec un don remarquable pour la gestion des couleurs mais pas que... ",
      groupe: "GRAPHISME",
    },
    {
      nom: "Rodline LINEAU",
      poste: "ONM / Graphisme",
      desc: "Rapide et douée, elle explore toujours diverses pistes !",
      groupe: "GRAPHISME",
    },
    {
      nom: "Yassmina LAABAD",
      poste: "ONM / Graphisme et art",
      desc: "Le sens de la compo, partie prenante de toutes les aventures !",
      groupe: "GRAPHISME",
    },
  ],
  makers: [
    {
      nom: "Guido",
      poste: "Designer, bricoleur",
      desc: "Master maker, il gère le hors système !",
      groupe: "MAKERS",
    },
    {
      nom: "Arnaud TORIT",
      poste: "Chroniqueur / Illustrateur / Animateur 3D / Musicien",
      desc: "Inventif, il explore à sa manière les technos tous azimuts",
      groupe: "MAKERS",
    },
  ],
  photo: [
    {
      nom: "Aziz ARY NETO",
      poste: "Photographe / Architecte / Designer",
      desc: "Responsable du Pôle Photo, grand organisateur, il fait bouger les lignes",
      groupe: "PHOTO",
    },
    {
      nom: "Enzo LAUZANNE",
      poste: "ONM / Photo",
      desc: "Passionné de photo, il se découvre pédagogue",
      groupe: "PHOTO",
    },
    {
      nom: "Jimmy BOBROWSKI",
      poste: "ONM / Photo / Audiovisuel",
      desc: "Fiable et la vidéo de A à Z",
      groupe: "PHOTO",
    },
    {
      nom: "Fiyametta YOHANNES",
      poste: "ONM / Photo",
      desc: " Photo et RS sont ses outils de prédilection !",
      groupe: "PHOTO",
    },
    {
      nom: "Yohann FRANCO",
      poste: "ONM / Retouche photo",
      desc: "Curieux de tout. Sa force : la maîtrise absolue de Photoshop !",
      groupe: "PHOTO",
    },
  ],
  audiovisuel: [
    {
      nom: "Mohamed ABAKAR",
      poste: "Vidéaste intuitif et excessif",
      desc: "Responsable du Pôle Audiovisuel, l’homme qui parle aux caméras.",
      groupe: "AUDIOVISUEL",
    },
    {
      nom: "Stephen HEBDING",
      poste: "ONM /  Audiovisuel",
      desc: "Road movie maker enthousiaste, il gère nos tournages.",
      groupe: "AUDIOVISUEL",
    },
    {
      nom: "Rayan BARR",
      poste: "ONM / Audiovisuel",
      desc: "Vidéaste en devenir, il fonce droit devant.",
      groupe: "AUDIOVISUEL",
    },
    {
      nom: "Julie ARAMINTHE",
      poste: "ONM / Audiovisuel",
      desc: "Un regard caméra, même en graphisme !",
      groupe: "AUDIOVISUEL",
    },
  ],
  monteurs: [
    {
      nom: "Marline AFFAYERE",
      poste: "ONM / Audiovisuel",
      desc: "Concentrée, ne perd jamais le fil d’un montage !",
      groupe: "AUDIOVISUEL",
    },
    {
      nom: "Ricardo PALMA",
      poste: "ONM / Photo",
      desc: "Photographe poète solide, le silence est son compagnon.",
      groupe: "AUDIOVISUEL",
    },
    {
      nom: "Guillaume PAGES",
      poste: "Chroniqueur / Monteur",
      desc: "Jovial, capable de trouver le fil rouge de tout montage !",
      groupe: "AUDIOVISUEL",
    },
    {
      nom: "Mehdi MANSOURI",
      poste: "Monteur streaming",
      desc: "Optimiste fou de sport, il rêve de streamer des matchs.",
      groupe: "AUDIOVISUEL",
    },
  ],
  reporters: [
    {
      nom: "Alexandre FRANCOIS",
      poste: "ONM / Audiovisuel",
      desc: "Intelligent, il a l’étoffe d’un réalisateur",
      groupe: "REPORTER",
    },
    {
      nom: "Catia BARRETO",
      poste: "ONM / Audiovisuel",
      desc: "Notre ingénieure  son tout terrain, méticuleuse une barrière contre les sons indésirables !",
      groupe: "REPORTER",
    },
    {
      nom: "Osama FIDAIL",
      poste: "ONM / Audiovisuel Photo",
      desc: "Reporter photo, discret qui saisit au vol le cadrage juste",
      groupe: "REPORTER",
    },
    {
      nom: "Thibault LAFITTE",
      poste: "ONM / Audiovisuel Photo",
      desc: "Rédacteur podcast, les mots avant tout",
      groupe: "REPORTER",
    },
    {
      nom: "Angelique MEZBOURIAN",
      poste: "ONM / Son Graphisme",
      desc: "Éclectique, navigue entre musique et écriture, arts plastiques et numérique",
      groupe: "REPORTER",
    },
    {
      nom: "Merwan NAHAR",
      poste: "ONM / Audiovisuel",
      desc: "Confiant, il poursuit sa passion pour la  vidéo.",
      groupe: "REPORTER",
    },
    {
      nom: "Omar ALMOHAMMAD",
      poste: "ONM / Audiovisuel",
      desc: "Journaliste professionnel, il étoffe son parcours avec le  volet audiovisuel",
      groupe: "REPORTER",
    },
  ],
  son: [
    {
      nom: "Mario PLANET",
      poste: "Musicien / Compositeur / Sociologue",
      desc: "Responsable du Pôle Son, il compose même la bonne humeur.",
      groupe: "SON",
    },
    {
      nom: "Nicolas ASSIE",
      poste: "Ingénieur son",
      desc: "Consciencieux et précis, il gère notre studio son.",
      groupe: "SON",
    },
    {
      nom: "Zahra TAHIB",
      poste: "ONM / Son",
      desc: "Battante, dans sa ligne de mire la MAO.",
      groupe: "SON",
    },
  ],
  labComputerMusic: [
    {
      nom: "Mustapha ELNOOR",
      poste: "ONM / Son et Photo...",
      desc: "Musicologue, chercheur et novateur, il donne du sens aux choses.",
      groupe: "SON",
    },
    {
      nom: "Tara BAKHT TALE",
      poste: "ONM / Son",
      desc: "Musicienne et chanteuse, s’attaque à la MAO avec un grand sourire.",
      groupe: "SON",
    },
    {
      nom: "Mohsen FAZELI",
      poste: "ONM / Son et Photo",
      desc: "Instrumentiste prodige du tar, il produit ses propres sonorités numériques.",
      groupe: "SON",
    },
    {
      nom: "Kaysar ABOU ZER",
      poste: "ONM / Son, Audiovisuel",
      desc: "Instrumentiste prodige du tar, il produit ses propres sonorités numériques.",
      groupe: "SON",
    },

    {
      nom: "Adib DARKASHALLI",
      poste: "ONM / Son",
      desc: "Guitariste professionnel d’exception, il ajoute la corde numérique à son arc",
      groupe: "SON",
    },
    {
      nom: "Nasima ARKIN",
      poste: "ONM / Son et Graphisme",
      desc: "Entre musique  et stylisme, le numérique comme  terrain d’exploration",
      groupe: "SON",
    },
    {
      nom: "Azamat ABDURAKHMANOV",
      poste: "ONM / Son",
      desc: "Artiste pluriel, brillant, le numérique saura polier ses facettes",
      groupe: "SON",
    },
  ],
  label: [
    {
      nom: "Wadii HAMOUDA",
      poste: "Ingénieur son, Compositeur MAO",
      desc: "Entreprenant, il gère notre studio son.",
      groupe: "SON",
    },
    {
      nom: "Wael JEGHAM",
      poste: "Ingénieur son, compositeur MAO et DJ",
      desc: "Passionné et proactif, il porte notre Label",
      groupe: "SON",
    },
    {
      nom: "Andy LEPAGE",
      poste: "Visual Sound Designer",
      desc: "Rapide, il jongle avec talent entre musique et animation",
      groupe: "SON",
    },
  ],
  motionDesign: [
    {
      nom: "Dorian GADEGBEKU",
      poste: "Motion designer / Vidéaste",
      desc: "Multiples talents, amateur des drones et d’autres objets numériques",
      groupe: "MOTION",
    },
    {
      nom: "Axel BERNARD",
      poste: "ONM / Motion design et 3D",
      desc: "Passionné, son rêve se lancer pleinement dans l’aventure de la 3D",
      groupe: "MOTION",
    },
    {
      nom: "Olivier CAMOIN",
      poste: "ONM / Vidéo, Motion Design, Graphisme",
      desc: "L’air de rien, il est à sa place à chaque étape de la chaîne.",
      groupe: "MOTION",
    },
  ],
};

export function getColor(groupe) {
  let color = null;
  if (groupe === "STAFF" || groupe === "MAKERS") {
    color = "#222";
  } else if (groupe === "WEB" || groupe === "REPORTER") {
    color = "#C00068";
  } else if (groupe === "GRAPHISME") {
    color = "#c82323";
  } else if (groupe === "PHOTO") {
    color = "#005b3d";
  } else if (groupe === "AUDIOVISUEL" || groupe === "MOTION") {
    color = "#3069d7";
  } else if (groupe === "SON") {
    color = "#f7d740";
  }
  return color;
}
