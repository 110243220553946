import React, { useEffect, useRef, useState } from "react";

import { v4 as uuidv4 } from "uuid";

import { employees, getColor } from "../helpers/employees";
import { useAxios } from "../helpers/axios";
import { Card } from "./Card";
import { motion } from "framer-motion";
import { carouselVar } from "../helpers/variants/card.variants";

function Carousel() {
  const [carouselConstrains, setCarouselConstrains] = useState({
    right: 0,
    left: -window.innerWidth,
  });
  const carouselRef = useRef(null);
  const paths = useAxios("/photos/paths");
  useEffect(() => {
    console.log(paths);
    const cards = document.getElementsByClassName("card");
    const cardWidth = cards[0].getBoundingClientRect().width;
    if (carouselRef !== null) {
      setCarouselConstrains({
        left: -(cardWidth * cards.length) + cardWidth,
        right: 0,
      });
    }
  }, [carouselRef]);

  const handleCardInfos = (employee) => {
    let currentPath = "";
    let color = getColor(employee.groupe);

    if (paths !== null) {
      paths.forEach((p) => {
        if (p.split(".")[0] === employee.nom) {
          currentPath = p;
        }
        return;
      });
    }
    const data = { ...employee, path: currentPath, color: color };

    return <Card data={data} key={uuidv4()} />;
  };

  return (
    <motion.div
      className="carousel"
      ref={carouselRef}
      drag="x"
      dragConstraints={{
        right: carouselConstrains.right,
        left: carouselConstrains.left,
      }}
      variants={carouselVar}
      initial="hidden"
      animate="show"
    >
      {employees.staff.map(handleCardInfos)}
      {employees.web.map(handleCardInfos)}
      {employees.graphisme.map(handleCardInfos)}
      {employees.petiteAgence.map(handleCardInfos)}
      {employees.makers.map(handleCardInfos)}
      {employees.photo.map(handleCardInfos)}
      {employees.audiovisuel.map(handleCardInfos)}
      {employees.monteurs.map(handleCardInfos)}
      {employees.reporters.map(handleCardInfos)}
      {employees.son.map(handleCardInfos)}
      {employees.labComputerMusic.map(handleCardInfos)}
      {employees.label.map(handleCardInfos)}
      {employees.motionDesign.map(handleCardInfos)}
    </motion.div>
  );
}

export default Carousel;

// function Trombinoscope() {
//   const paths = useAxios("/photos/paths");

//   const [cardWidth, setCardWidth] = useState(300);
//   const [cardHolderWidth, setCardHolderWidth] = useState(null);

//   const [targetPosition, setTargetPosition] = useState(0); // The position seeked by the container

//   const dragCostraintRef = useRef(null);
//   const cardHolderRef = useRef(null);

//   useEffect(() => {
//     const childCount = cardHolderRef.current.childElementCount;
//     setCardHolderWidth(300 * childCount);
//     cardHolderRef.current.style.width = `${cardHolderWidth}px`;
//   }, [cardHolderWidth]);

//   const handleCardInfos = (employee, i) => {
//     let currentPath = "";
//     let color = getColor(employee.groupe);

//     if (paths !== null) {
//       paths.forEach((p) => {
//         if (p.split(".")[0] === employee.nom) {
//           currentPath = p;
//         }
//         return;
//       });
//     }

//     const data = { ...employee, path: currentPath, color: color };
//     return <Card data={data} key={uuidv4()} />;
//   };
//   const handleNav = (e) => {
//     // setTargetPosition()
//     console.log(e.target.id);
//   };

//   const onPan = (event, info) => {
//     // setTargetPosition((prev) => prev + info.offset.x / 10);
//     // console.log(event);
//     console.log(info);
//   };

//   return (
//     <div className="trombinoscope">
//       <motion.div
//         className="card_holder"
//         ref={cardHolderRef}
//         animate={{ x: targetPosition, transition: { ease: "linear" } }}
//         // drag="x"
//         // layout
//         // dragConstraints={{ left: -cardHolderWidth + 500, right: 0 }}
//         // onPan={onPan}
//       >
//         <AnimatePresence>
//           {employees.staff.map(handleCardInfos)}
//           {employees.web.map(handleCardInfos)}
//           {employees.graphisme.map(handleCardInfos)}
//           {employees.petiteAgence.map(handleCardInfos)}
//           {employees.makers.map(handleCardInfos)}
//           {employees.photo.map(handleCardInfos)}
//           {employees.audiovisuel.map(handleCardInfos)}
//           {employees.monteurs.map(handleCardInfos)}
//           {employees.reporters.map(handleCardInfos)}
//           {employees.son.map(handleCardInfos)}
//           {employees.labComputerMusic.map(handleCardInfos)}
//           {employees.label.map(handleCardInfos)}
//         </AnimatePresence>
//       </motion.div>
//       <TrombiNav handleNav={handleNav} />
//       <div ref={dragCostraintRef} style={{ position: "absolute", width: 30 }} />
//     </div>
//   );
// }

// const Card = ({ data }) => {
//   const [isHovered, setIsHovered] = useState(false);

//   const toggleInfo = () => {
//     setIsHovered(!isHovered);
//   };

//   const spring = {
//     type: "spring",
//     damping: 13,
//     stiffness: 200,
//   };

//   const variant = {
//     visible: {
//       opacity: 1,
//       y: 0,
//     },
//     hidden: {
//       opacity: 0,
//       y: 50,
//     },
//   };

//   return (
//     <motion.div
//       className="card_container"
//       onPointerEnter={toggleInfo}
//       onPointerLeave={toggleInfo}
//       onTap={window.innerWidth <= 400 ? toggleInfo : null}
//     >
//       <div className="card_image_container">
//         {data.path && (
//           <img
//             src={`http://api.maignanb.com/static/images/${data.path}`}
//             alt={data.nom}
//           />
//         )}
//       </div>
//       <motion.div
//         className="card_infos"
//         style={{ backgroundColor: data.color }}
//         variants={variant}
//         initial="hidden"
//         animate={isHovered ? "visible" : "hidden"}
//         transition={spring}
//       >
//         <h3 className="card_infos_name">{data.nom}</h3>

//         <p>
//           <span className="card_infos_trait">{data.poste}</span>
//           <span className="card_infos_trait" style={{ fontSize: "16px" }}>
//             {data.desc}
//           </span>
//         </p>
//       </motion.div>
//     </motion.div>
//   );
// };
